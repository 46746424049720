<template>
   <div v-if="pageIsReady" class="navPush">

      <div class="header">
         <h1>{{ title }}</h1>
         <div v-if="filterBy === 'genres'">
            <ul>
               <li class="syllables" v-if="tag.syllables">{{ tag.syllables }}</li>
               <li class="pronunciation" v-if="tag.pronunciation">
                  <span>{{ tag.pronunciation }}</span>
               </li>
            </ul>
         </div>
         <p v-if="definition">{{ definition }}</p>
      </div>

      <div v-if="results.length">
         <EntryList :entries="results" :social="content.social" />
      </div>
      <p v-else>No results for this filter.</p>
   </div>
</template>

<script>
    import _ from 'lodash';
    import {getMetaTags} from '@/plugins/functions.js';
    import EntryList from '@/components/playlists/EntryList.vue';

    export default {
        name: 'FilterResults',
        components: {
            EntryList
        },
        data() {
            return {
                title: '',
                pageIsReady: false,
                playlists: this.content.playlists,
                tag: {},
                results: [],
            }
        },
        props: {
            filterBy: String,
            content: Object,
            slug: String,
        },
        computed: {
            definition() {
                if (! this.pageIsReady) {
                    return '';
                }

                let count = this.results.length + (this.results.length === 1 ? ' playlist' : ' playlists');
                switch (this.filterBy) {
                    case 'genres':
                        return this.tag.definition + ' ' + count + ' with this genre.';
                    case 'moods':
                        return count + ' with this mood.';
                    case 'colors':
                        return count + ' with this color.';
                    case 'activities':
                        return count + ' ' + this.tag.definition;
                    case 'times':
                        return count + ' ' + this.tag.definition;
                    default:
                        return count;
                }
            }
        },
        methods: {
            getMetaTags,
            getResults() {
                // if playlists aren't loaded yet, don't do anything
                if (!this.playlists.length) {
                    return;
                }

                // Set the Tag! look through the filters object in content and find the one that matches the slug
                this.tag = _.find(this.content.filters[this.filterBy], (o) => {
                    return o.slug === this.slug;
                });

                // if the tag doesn't exist, 404 the page
                if (_.isEmpty(this.tag)) {
                    this.$router.replace({name: 'pageNotFound'});
                    return;
                }

                // loop through playlists, then loop through its tags for this tag group. if it contains
                // a tag whose slug matches the url's, add that playlist to the results.
                this.playlists.forEach((playlist) => {
                    let tags = playlist[this.filterBy];
                    tags.forEach((tag) => {
                        if (tag.slug === this.slug) {
                            this.results.push(playlist);
                        }
                    });
                });

                // set the title
                this.title = this.tag.title;

                // sort alphabetically by title if results
                if (! _.isEmpty(this.results)) {
                    _.sortBy(this.results, 'title');
                }

                // results are ready (even if empty results array)
                this.pageIsReady = true;
            },
        },
        watch: {
            playlists() {
                this.getResults();
            }
        },
        mounted() {
            this.getResults();
        },
        metaInfo() {
            if (this.pageIsReady) {
                let meta = this.content.filters.meta[this.filterBy];
                return this.getMetaTags(
                    _.replace(meta.title, '{#}', _.startCase(this.tag.title)),
                    meta.url + this.tag.slug,
                    meta.image,
                    _.replace(meta.description, '{#}', _.toLower(this.tag.title))
                );
            }

            return {};
        },
    }
</script>

<style scoped lang="scss">
   .header {
      li {
         display: inline;
         font-size: 1.5rem;
         color: $text-color;
         cursor: default;

         // syllables
         &.syllables + li.pronunciation {
            &::before {
               content: '|';
               margin: 0 10px 0 20px;
            }
         }
         // pronunciation
         &.pronunciation {
            span::before, span::after {
               content: '\\';
               margin: 0 10px;
            }
         }
      }
   }

   h1 {
      font-family: $font-family-serif;
      font-size: 2.5rem;
      font-style: normal;
      font-weight: $font-weight-bold;
      letter-spacing: 2.5px;
      color: $black;
      line-height: 1;
      vertical-align: middle;
   }

   @include media-breakpoint-up(lg) {
      h1 {
         font-size: 5rem;
      }
   }
</style>